import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
        <article>
          <h1 className="title is-1">{post.frontmatter.title}</h1>
          <h2 className="subtitle is-3 has-text-grey">{post.frontmatter.date}</h2>
          <p className="is-size-4" dangerouslySetInnerHTML={{ __html: post.html }}></p>
		    </article>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
      }
    }
  }
`